import { Textarea } from "@chakra-ui/react";

export default function TextareaComponent({ value, onChange }) {
  return (
    <Textarea
      focusBorderColor="brand.900"
      placeholder="Message/Comment"
      value={value}
      onChange={onChange}
    />
  );
}
