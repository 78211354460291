import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ImageComponent from "./Image";

import logo from "../images/logo.png";
import ritan from "../images/ritan.png";
import TextComponent from "./Text";
import HeadingComponent from "./Heading";

import { MdOutlinePermContactCalendar } from "react-icons/md";
import { MdOutlineImportContacts } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Flex
      align="center"
      justify="center"
      wrap="wrap"
      gap={{ base: "2rem", md: "2rem", lg: "0rem" }}
      bg="#333"
      py="3rem"
      px={{ base: "0.5rem", md: "0.5rem", lg: "6rem" }}
    >
      <Flex
        align={{ base: "center", md: "center", lg: "start" }}
        height={{ base: "100%", md: "100%", lg: "200px" }}
        w={{ base: "100%", md: "100%", lg: "30%" }}
        direction={{ base: "row", md: "row", lg: "column" }}
        gap="1rem"
        justify={{ base: "center", md: "center", lg: "start" }}
      >
        <Box w={{ base: "120px", md: "120px", lg: "257px" }}>
          <ImageComponent src={logo} alt="logo" />
        </Box>
        <TextComponent
          title="Partners with"
          color="white"
          weight="bold"
          align="start"
          size={{ base: "16px", md: "16px", lg: "20px" }}
        />
        <Box w={{ base: "120px", md: "120px", lg: "257px" }}>
          <ImageComponent src={ritan} alt="logo" />
        </Box>
      </Flex>
      <Flex
        align="start"
        height={{ base: "100%", md: "100%", lg: "200px" }}
        w={{ base: "100%", md: "100%", lg: "30%" }}
        direction="column"
        gap="1rem"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <HeadingComponent
          title="Main Office"
          size="20px"
          weight="bold"
          align="start"
          color="#fff"
        />
        <Flex align="center" gap="1rem">
          <Box fontSize="18px" color="white">
            <MdOutlinePermContactCalendar />
          </Box>
          <TextComponent
            title="McDonough, GA 30253"
            color="#fff"
            size="14px"
            weight="normal"
          />
        </Flex>

        <Flex align="center" gap="1rem">
          <Box fontSize="18px" color="white">
            <MdOutlineImportContacts />
          </Box>
          <TextComponent
            title="+1 (917) 645-1062"
            color="#fff"
            size="14px"
            weight="normal"
          />
        </Flex>

        <Flex align="center" gap="1rem">
          <Box fontSize="18px" color="white">
            <MdOutlineImportContacts />
          </Box>
          <TextComponent
            title="+1 (917) 826-6962"
            color="#fff"
            size="14px"
            weight="normal"
          />
        </Flex>
      </Flex>
      <Flex
        align="start"
        height={{ base: "100%", md: "100%", lg: "200px" }}
        w={{ base: "100%", md: "100%", lg: "20%" }}
        direction="column"
        gap="1rem"
        display={{ base: "none", md: "none", lg: "flex" }}
      >
        <HeadingComponent
          title="Useful links"
          size="20px"
          weight="bold"
          align="start"
          color="#fff"
        />
        <Flex align="start" direction="column" w="100%" gap="1rem">
          <Link style={{ fontSize: "16px", color: "white" }} to="/services">
            Our Services
          </Link>
          <Link style={{ fontSize: "16px", color: "white" }} to="/about">
            About Us
          </Link>

          <Link style={{ fontSize: "16px", color: "white" }} to="/privacy">
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
      <Flex
        align={{ base: "center", md: "center", lg: "start" }}
        height={{ base: "100%", md: "100%", lg: "200px" }}
        w={{ base: "100%", md: "100%", lg: "20%" }}
        direction="column"
        gap="1rem"
      >
        <HeadingComponent
          title="Socials"
          size="20px"
          weight="bold"
          align="start"
          color="#fff"
        />
        <Flex align="center" gap="1rem">
          <Box fontSize="16px" color="white">
            <a
              href="https://www.facebook.com/steppingglory/"
              rel="noreferrer"
              target="_blank"
            >
              <FaFacebookF fontSize="20px" />
            </a>
          </Box>
          <Box fontSize="16px" color="white">
            <a
              href="https://www.instagram.com/steppingglory/"
              rel="noreferrer"
              target="_blank"
            >
              <AiOutlineInstagram fontSize="20px" />
            </a>
          </Box>

          <Box fontSize="16px" color="white">
            <a
              href="https://www.youtube.com/@SteppingGloryDeveloperLL-nq3io"
              rel="noreferrer"
              target="_blank"
            >
              <AiOutlineYoutube fontSize="20px" />
            </a>
          </Box>
          <Box fontSize="16px" color="white">
            <a
              href="https://wa.link/steppingglory"
              rel="noreferrer"
              target="_blank"
            >
              <AiOutlineWhatsApp fontSize="20px" />
            </a>
          </Box>
        </Flex>
      </Flex>
      <Box mt="6rem">
        <TextComponent
          title="Copyright 2023, All Rights Reserved"
          color="#fff"
          size="14px"
          weight="normal"
          align="center"
        />
      </Box>
    </Flex>
  );
}
