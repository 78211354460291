import axios from "axios";

const API_URL = "https://loya-api.ritan360dev.com.ng/api";

export async function sendEmailMsg(data) {
  const response = await axios.post(`${API_URL}/email`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
}
