import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import ImageComponent from "../ui/Image";

import workImage from "../images/work.png";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";
import ButtonComponent from "../ui/Button";

export default function Workflow({ onOpen }) {
  return (
    <Flex
      p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      bg="#fff"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      gap={{ base: "2rem", md: "2rem", lg: "0rem" }}
    >
      <Flex
        w={{ base: "100%", md: "100%", lg: "65%" }}
        align="start"
        direction="column"
        justify="center"
        gap="1rem"
        px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
        // height="900px"
      >
        <TextComponent
          title="Our work flow"
          color="brand.900"
          align="start"
          weight="bold"
          size="24px"
        />
        <HeadingComponent
          title="We love What we Do"
          size={{ base: "30px", md: "30px", lg: "40px" }}
          color="#000"
          weight="bold"
          align="start"
          width={{ base: "90%", md: "90%", lg: "70%" }}
        />
        <TextComponent
          color="#000"
          title="We love what we do because we get to help businesses succeed. We love the challenge of finding solutions to complex problems. We love the creativity that comes with designing and developing new products and services. And we love the satisfaction of seeing our clients achieve their goals."
          weight="normal"
          size="20px"
          align="start"
          width="100%"
        />
        <Flex w="100%" align="center" wrap="wrap" justify="space-between">
          <Flex
            height={{ base: "500px", md: "500px", lg: "250px" }}
            w="50%"
            align="start"
            direction="column"
            gap="1rem"
          >
            <HeadingComponent
              align="start"
              size="24px"
              weight="bold"
              title="Research"
              color="#000"
            />
            <TextComponent
              color="#000"
              title="We believe that research is essential to success. We invest heavily in research and development, and we are always looking for new ways to improve our products and services. We believe that by understanding our clients' needs and the latest trends, we can create solutions that are truly innovative.
              "
              weight="normal"
              size="16px"
              align="start"
              width="90%"
            />
          </Flex>

          <Flex
            height={{ base: "500px", md: "500px", lg: "250px" }}
            w="50%"
            align="start"
            direction="column"
            gap="1rem"
          >
            <HeadingComponent
              align="start"
              size="24px"
              weight="bold"
              title="Data Collection"
              color="#000"
            />
            <TextComponent
              color="#000"
              title="We collect data from a variety of sources, including surveys, interviews, and social media. We use this data to understand our clients' needs, to identify trends, and to measure the effectiveness of our marketing campaigns. We believe that data is essential to making informed decisions, and we are committed to using data to improve our products and services.
              "
              weight="normal"
              size="16px"
              align="start"
              width="90%"
            />
          </Flex>

          <Flex
            height={{ base: "500px", md: "500px", lg: "250px" }}
            w="50%"
            align="start"
            direction="column"
            gap="1rem"
          >
            <HeadingComponent
              align="start"
              size="24px"
              weight="bold"
              title="Targetting"
              color="#000"
            />
            <TextComponent
              color="#000"
              title="We target our marketing campaigns to specific audiences. We use data to identify our target audiences, and we create marketing messages that are relevant to their interests. We believe that by targeting our marketing campaigns, we can improve the effectiveness of our marketing efforts and reach our target audiences more effectively.
              "
              weight="normal"
              size="16px"
              align="start"
              width="90%"
            />
          </Flex>

          <Flex
            height={{ base: "500px", md: "500px", lg: "250px" }}
            w="50%"
            align="start"
            direction="column"
            gap="1rem"
          >
            <HeadingComponent
              align="start"
              size="24px"
              weight="bold"
              title="Problem Solving"
              color="#000"
            />
            <TextComponent
              color="#000"
              title="We are problem solvers. We love to find solutions to complex problems. We believe that by thinking outside the box, we can come up with creative solutions that no one else has thought of. We are committed to solving our clients' problems, and we are always looking for new ways to improve our products and services.
              "
              weight="normal"
              size="16px"
              align="start"
              width="90%"
            />
          </Flex>
        </Flex>
        <Flex align="start">
          <ButtonComponent onClick={onOpen} title="Get Started" size="lg" />
        </Flex>
      </Flex>
      <Box w={{ base: "100%", md: "100%", lg: "35%" }}>
        <ImageComponent alt="services" src={workImage} height="703px" />
      </Box>
    </Flex>
  );
}
