import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";
import {
  MdOutlineImportContacts,
  MdOutlinePermContactCalendar,
} from "react-icons/md";

export default function Hero() {
  return (
    <Flex
      w="80%"
      mx="auto"
      align="start"
      direction="column"
      justify="center"
      gap="1rem"
      px="2.5rem"
      py="4rem"
      height="100%"
    >
      <HeadingComponent
        title="Privacy Policy Page"
        size="20px"
        color="brand.900"
        weight="bold"
        align="start"
        width="70%"
      />{" "}
      <HeadingComponent
        title="Introduction"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="We have a proven track record of success in helping businesses of all sizes achieve their digital goals. We have worked with businesses in a variety of industries, including retail, e-commerce, healthcare, and finance."
        weight="normal"
        size="20px"
        align="start"
        width="100%"
      />
      <HeadingComponent
        title="What Information Do We Collect?"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="We collect the following information from our website visitors and customers:

        Contact information, such as your name, email address, and phone number.
        Demographic information, such as your age, gender, and location.
        Information about your interests and preferences.
        Information about your website activity, such as the pages you visit and the links you click.
        How Do We Use Your Information?"
        size="20px"
        align="start"
        width="100%"
        weight="normal"
      />
      <HeadingComponent
        title="We use the information we collect from you to:"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="Provide you with the services you have requested.
        Communicate with you about your account, services, and marketing offers.
        Personalize your experience on our website.
        Improve our products and services.
        Comply with applicable laws and regulations."
        size="20px"
        align="start"
        width="100%"
        weight="normal"
      />
      <HeadingComponent
        title="How Do We Share Your Information?"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="We may share your information with third parties in the following limited circumstances:

        With our service providers who help us provide you with the services you have requested.
        With our marketing partners who help us deliver marketing messages to you.
        With our affiliates who help us provide you with products and services.
        In response to a subpoena, court order, or other legal process.
        To protect our rights or property.
        To protect the safety of our customers or others.
        "
        size="20px"
        align="start"
        width="100%"
        weight="normal"
      />
      <HeadingComponent
        title="How Do We Protect Your Information?"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="We take steps to protect your information from unauthorized access, use, and disclosure. These steps include:

        Using industry-standard security measures, such as firewalls and encryption.
        Limiting access to your information to authorized employees.
        Training our employees on the importance of privacy and security."
        size="20px"
        align="start"
        width="100%"
        weight="normal"
      />
      <HeadingComponent
        title="How Can You Control Your Information?"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="You have the following rights with respect to your information:

        The right to access your information.
        The right to correct any inaccuracies in your information.
        The right to request that we delete your information.
        The right to opt out of receiving marketing messages from us.
        You can exercise these rights by contacting us at the following address:
        "
        size="20px"
        align="start"
        width="100%"
        weight="normal"
      />
      <HeadingComponent
        title="Changes to This Privacy Policy"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="We may update this privacy policy from time to time. If we make any material changes to this policy, we will notify you by email or through a prominent notice on our website.
      "
        size="20px"
        align="start"
        width="100%"
        weight="normal"
      />
      <HeadingComponent
        title="Contact Us"
        size="24px"
        color="#333"
        weight="bold"
        align="start"
        width="70%"
      />
      <TextComponent
        color="#000"
        title="If you have any questions about this privacy policy, please contact us at the following address:
    "
        size="20px"
        align="start"
        width="100%"
        weight="normal"
      />
      <Flex align="start" height="100%" w="30%" direction="column" gap="1rem">
        <HeadingComponent
          title="123, main street"
          size="20px"
          weight="bold"
          align="start"
          color="#333"
        />
        <Flex align="center" gap="1rem">
          <Box fontSize="18px" color="#000">
            <MdOutlinePermContactCalendar />
          </Box>
          <TextComponent
            title="McDonough, GA 30253"
            color="#333"
            size="14px"
            weight="normal"
          />
        </Flex>

        <Flex align="center" gap="1rem">
          <Box fontSize="18px" color="#000">
            <MdOutlineImportContacts />
          </Box>
          <TextComponent
            title="+1 (917) 645-1062"
            color="#333"
            size="14px"
            weight="normal"
          />
        </Flex>

        <Flex align="center" gap="1rem">
          <Box fontSize="18px" color="#000">
            <MdOutlineImportContacts />
          </Box>
          <TextComponent
            title="+1 (917) 826-6962"
            color="#333"
            size="14px"
            weight="normal"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
