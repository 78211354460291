import { Button } from "@chakra-ui/react";

export default function ButtonComponent({
  title,
  bg,
  color,
  weight,
  size,
  variant,
  onClick,
}) {
  return (
    <Button
      bg={bg ? bg : "brand.900"}
      color={color ? color : "white"}
      variant={variant ? variant : "solid"}
      size={size}
      fontWeight={weight ? weight : "medium"}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
