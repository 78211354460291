import { Select, FormControl, FormLabel } from "@chakra-ui/react";

export default function SelectComponent({ options, label, onChange }) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Select
        onChange={onChange}
        focusBorderColor="brand.900"
        placeholder="Select option"
      >
        {options.map((el) => (
          <option value={el}>{el}</option>
        ))}
      </Select>
    </FormControl>
  );
}
