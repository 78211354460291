import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ImageComponent from "../ui/Image";

import serviceImage from "../images/african.jpg";
import TextComponent from "../ui/Text";
import HeadingComponent from "../ui/Heading";
import ButtonComponent from "../ui/Button";

export default function ChooseUs({ onOpen }) {
  return (
    <Flex
      p={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
      wrap="wrap"
      bg="#F0EFEF"
      align="center"
      justify="space-between"
    >
      <Box w={{ base: "100%", md: "100%", lg: "50%" }}>
        <ImageComponent alt="services" src={serviceImage} height="598px" />
      </Box>
      <Flex
        w={{ base: "100%", md: "100%", lg: "50%" }}
        align="start"
        direction="column"
        justify="center"
        gap="1rem"
        px={{ base: "0.5rem", md: "0.5rem", lg: "2.5rem" }}
        height="100%"
      >
        <TextComponent
          title="Why Choose Us"
          color="brand.900"
          align="start"
          weight="bold"
          size="24px"
        />
        <HeadingComponent
          title="Exceptional Solution for Digital Business Model"
          size={{ base: "30px", md: "30px", lg: "40px" }}
          color="#000"
          weight="bold"
          align="start"
          width={{ base: "90%", md: "90%", lg: "70%" }}
        />
        <TextComponent
          color="#000"
          title="We offer a comprehensive solution for digital business models, including website design, development, mobile app development, SEO, and digital marketing. We work with our clients to understand their needs and goals, and we develop a customized solution that meets their specific requirements."
          weight="normal"
          size="20px"
          align="start"
          width="100%"
        />
        <TextComponent
          color="#000"
          title="We have a proven track record of success in helping businesses of all sizes achieve their digital goals. We have worked with businesses in a variety of industries, including retail, e-commerce, healthcare, and finance."
          weight="normal"
          size="20px"
          align="start"
          width="100%"
        />
        <TextComponent
          color="#000"
          title="We are committed to providing our clients with the best possible service. We are always available to answer questions and provide support. We also offer a satisfaction guarantee on all of our services."
          weight="normal"
          size="20px"
          align="start"
          width="100%"
        />
        <Flex align="end" w="100%" justify="end" pr="5rem">
          <ButtonComponent
            title="Get Started"
            bg="brand.900"
            color="white"
            size="lg"
            onClick={onOpen}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
