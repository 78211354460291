import React, { Fragment } from "react";
import Header from "../ui/Header";
import Hero from "./Hero";
import OurServices from "./OurServices";
import ChooseUs from "./ChooseUs";
import Workflow from "./Workflow";
import Footer from "../ui/Footer";
import { useDisclosure } from "@chakra-ui/react";

export default function Services() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Fragment>
      <Header isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Hero />
      <OurServices />
      <ChooseUs onOpen={onOpen} />
      <Workflow onOpen={onOpen} />
      <Footer />
    </Fragment>
  );
}
