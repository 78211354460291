import React, { Fragment } from "react";
import Header from "../ui/Header";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import OurTeam from "./OurTeam";
import Footer from "../ui/Footer";
import { useDisclosure } from "@chakra-ui/react";

export default function About() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Fragment>
      <Header isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Hero />
      <AboutUs />
      {false && <OurTeam />}
      <Footer />
    </Fragment>
  );
}
