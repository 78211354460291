import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import Cleave from "cleave.js/react";

import InputComponent from "../ui/Input";
import SelectComponent from "../ui/Select";
import TextareaComponent from "../ui/Textarea";
import { useState } from "react";
import { sendEmailMsg } from "../../util/http";

export default function Contact({ isOpen, onClose }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [project, setProject] = useState("");
  const [project1, setProject1] = useState("");
  const [budget, setBudget] = useState();
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  async function sendMessage() {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      amount: budget,
      comment: message,
      telephone: phone,
      project_type: project,
    };

    try {
      await sendEmailMsg(data);

      alert("Message successfully sent");
      onClose();
    } catch (error) {
      onClose();

      alert(error?.response?.data.message || "something went wrong");
    }
  }

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Project Enquiry Form</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex align="center" w="100%" direction="column" gap="1rem">
            <Flex w="100%" align="center" justify="space-between" gap="1rem">
              <InputComponent
                label="First Name"
                placeholder="First Name"
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <InputComponent
                label="Last Name"
                placeholder="Last Name"
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </Flex>

            <InputComponent
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              placeholder="Email"
              type="email"
            />

            <Flex w="100%" align="center" justify="space-between" gap="1rem">
              <SelectComponent
                label="Project Type"
                onChange={(e) => setProject(e.target.value)}
                options={[
                  "Mobile App Development",
                  "Website Design",
                  "UI/UX",
                  "Content Development",
                  "SEO/SEM",
                ]}
              />
              {false && (
                <SelectComponent
                  label="Project Type"
                  onChange={(e) => setProject1(e.target.value)}
                  options={["Individual", "Corporate"]}
                />
              )}
            </Flex>
            <Cleave
              placeholder="$1,000"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
              }}
              style={{
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "0.4rem",
                outlineColor: "#C0561D",
                width: "100%",
              }}
              onChange={(e) => setBudget(Number(e.target.rawValue))}
            />
            {false && (
              <InputComponent
                label="Project Budget"
                placeholder="$1,000"
                type="number"
                onChange={(e) => setBudget(e.target.value)}
                value={budget}
              />
            )}
            <InputComponent
              label="Telephone No."
              placeholder="(917) 645-1062"
              type="number"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <TextareaComponent
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={sendMessage}
            variant="solid"
            bg="brand.900"
            color="white"
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
